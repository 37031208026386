import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Homepage from './Component/Homepage';
import Navbar from './Component/Navbar';
import Footer from './Component/Footer';
import ContactUs from './Component/ContactUs';
import AboutUs from './AboutUs';

function App() {
  return (
    <>
    <Navbar/>
    <Routes>
      <Route exact path='*' Component={Homepage}/>
      <Route exact path='/contact' Component={ContactUs}/>
      <Route exact path='/about' Component={AboutUs} />
    </Routes>
    <Footer/>
    </>
  );
};

export default App;
