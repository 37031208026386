import React from 'react'
import {Link} from 'react-router-dom'
import contact from './message.png'
import call from './phone-call.png'
import address from './location.png'

export default function Footer() {
  return (

    <div className="container-fluid footer">
        <div className='row footer-row'>
            <div className='col-md-3'>
                <p className="text-center">Copyright @<strong>Biznie.com</strong></p>
            </div>

            <div className='col-md-6'>
                <ul className='list-TC d-flex'>
                    <li className='me-0'><Link to='/'><img src={contact}  alt="logo" class="cont-icon" />
                    contact@biznie.com</Link></li>
                    <p class="d-lg-block d-md-block d-none">|</p>
                    <li className='ms-5 num'><Link to='/'><img src={call}  alt="logo" class="cont-icon" />9807489290</Link></li>
                </ul>
            </div>

            <div className='col-md-3'>
                <ul className='list-TC d-flex'>
                    <li className='me-2'><Link to='/'><img src={address}  alt="logo" class="cont-icon" />
                    Maldahiya, varanasi-221002</Link></li>
                </ul>
            </div>

        </div>
    </div>
    
  )
}
