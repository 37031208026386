import React from 'react'

export default function AboutUs() {
  return (
    <div className='container'>
        <h3 className='text-dark'>About us</h3>
        
        <p className='about-content'>
            <b className='text-primary'>Biznie.com</b> is an innovative e-commerce open marketplace designed to connect 
            sellers with retail, wholesale, and export/import buyers. Our platform serves as a 
            comprehensive solution for seamless business transactions, leveraging the 
            expertise of third-party technology partners for logistics and payment services.
            In today's digital era, many business owners aspire to expand their reach by 
            taking their offline stores online. However, the lack of technological knowledge 
            often poses a challenge. At Biznie, we bridge this gap by providing all the 
            necessary tools and integrations required to conduct business transactions 
            smoothly over the internet.<br></br><br></br>
            Our platform empowers sellers in the retail, wholesale, and export/import sectors
            to showcase their products in their respective markets. We offer a range of 
            services, including a secure payment gateway, escrow services, and reliable 
            logistics suppliers. With these resources, sellers can collect payments using 
            various methods such as <i>cash on delivery</i>  and other <i>online payment options</i>.
            <br></br><br></br>
            To ensure a safe and satisfactory transaction experience, we have implemented 
            the <b>"Biznie Pay"</b> feature. When a buyer places an order, we hold the payment 
            until the seller successfully delivers the goods. Once the buyer receives the order
            and expresses satisfaction, we release the payment to the seller, following the 
            completion of the seller's defined "Return Period."<br></br><br></br>
            Our goal is to provide a one-stop-shop solution for buyers and sellers across 
            different categories and genres through our e-commerce marketplace, 
            Biznie.com. Whether you are a retail buyer seeking unique products or a seller 
            looking to expand your business horizons, Biznie is here to simplify and enhance 
            your e-commerce journey. Join us today and experience the convenience and 
            efficiency of our comprehensive platform.
        </p>
    </div>
  )
}
