import React from 'react'
import {Link} from 'react-router-dom'
import Gif from './logo.gif'

export default function Homepage() {
  return (
  <>

    <div class="container">
      <div class="row">
        <div class="col-sm-6">
          <div class="card d-flex border-0">
              <div class="card-body mt-0">
                  <p class='card-text my-0'> Welcome to</p>
                  <p class="card-title my-0 Biz">Biznie.com</p>
                  <p class="card-text text-muted my-0 fs-5">One Platform, Infinite Possibilities</p>
                  <p class="card-text mt-3 mb-0">Uniting Buyers, Sellers, and Exporters in Online Commerce</p>
                  <p class="mt-3 mb-4"><span class="text1">Buyer </span><span class="text2 ms-2">Seller </span><span class="text3 ms-2"> Junction</span></p>
                  <hr></hr>
                  <p class="coming">Coming Soon...</p>
              </div>
          </div>
        </div>
          
        <div class="col-sm-6">
          <img src={Gif}  alt="logo" class="Gif" />
        </div>
      </div>
      <div class="row bizBtn mt-1">
          <div class="col-sm-12 d-flex flex-row justify-content-center">
              <Link class='btn btn-lg border border-1 border-primary fw-normal mx-0 join-btn' to='/contact'><span>Join Us</span></Link>
              <Link class='btn btn-lg border border-1 border-success fw-normal ms-3 app-btn' to='/'><span>Get App</span></Link>
          </div>
      </div>
    </div>

  </>
  )
}
