import React from 'react'
import {Link} from 'react-router-dom'
import logo from './logo.png'

export default function Navbar() {
  return (
  <>
  <div className='container-fluid'>
    <nav class="navbar navbar-expand-sm px-4 fixed-top">
      <Link class="navbar-brand" to="/"><img src={logo} alt='logo' className='BiznieLogo'/></Link>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#collapsibleNavbar">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse justify-content-end" id="collapsibleNavbar">
        <ul class="navbar-nav">
          <li class="nav-item me-3">
            <Link class="nav-link active" to="/">Home</Link>
          </li>
          <li class="nav-item me-3">
            <Link class="nav-link" to="/contact">Contact us</Link>
          </li>
          <li class="nav-item me-3">
            <Link class="nav-link" to="/about">About us</Link>
          </li>
        </ul>
      </div>
    </nav>
  </div>
  </>
  )
}
