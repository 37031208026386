import React from 'react'
import Gif from './logo.gif'

export default function ContactUs() {
  return (
  <>
    <div className="container">
      <div className="row contact">

        <div className="col-sm-6">

          <h2 class="text-primary fw-bolder">Contact Us</h2>
          <span class="text-muted fs-5 fw-normal">Please describe how can we help?</span>
          <form className='mt-4' action="mailto:Sudhanshu.kumar19782@gmail.com" method='post' enctype="text/plain">
            <div class="mb-3 mt-3">
              <input type="name" class="form-control" height="80" id="name" placeholder="Enter your name" name="YourName" required/>
            </div>
            <div class="mb-3">
              <input type="email" class="form-control" id="email" placeholder="Enter your email" name="email" required/>
            </div>
            <div class="mb-3">
              <input type="Number" class="form-control" id="num" placeholder="Enter your mobile number" name="number" required/>
            </div>
            <textarea class="form-control" rows="5" id="comment" name="text" placeholder='Please enter your queries ?' required></textarea>
            <div class="form-check my-3">
              <label class="form-check-label">
                <input class="form-check-input" type="checkbox" name="remember"/> Remember me
              </label>
            </div>
            <div className='mt-2'>
              <button type='reset' className='btn btn-success me-2' data-bs-toggle='tooltip' title='Clear Response'>Clear</button>
              <button type="submit" value='send' className="btn btn-primary ms-2" data-bs-toggle='tooltip' title='Submit Response'>Submit</button>
            </div>
          </form>
        </div>

        <div className='col-sm-6 d-lg-block d-md-block d-none'>
          <img src={Gif}  alt="logo" class="Gif" />
        </div>
      
      </div> 
    </div>
  </>
  )
}

